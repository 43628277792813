import React, { Component, createRef } from "react";
import axios from "../../axios";
import moment from "moment";
import { Helmet } from "react-helmet";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoMdSend } from "react-icons/io";
import { FiEdit2 } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";

import Button from "../../components/Button/Button";
import FormikControl from "../../components/FormikControl/FormikControl";
import Modal from "../../components/Modal/Modal";
import DialogBox from "../../components/DialogBox/DialogBox";

import styles from "./Index.module.css";

class Index extends Component {
  constructor() {
    super();
    this.msgInput = createRef();
  }

  state = {
    comments: [],
    message: null,
    formValues: null,
    showEditForm: false,
    showDeleteDialogBox: false,
    activeComment: null,
    pageSize: 10,
    totalCount: 0,
    isLoading: false,
  };

  componentDidMount() {
    this.fetchComments();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.pageSize !== prevState.pageSize) {
      this.fetchComments();
    }
  }

  fetchComments = () => {
    axios
      .get(`/comments?page=1&per-page=${this.state.pageSize}`)
      .then((response) => {
        this.setState({
          comments: response.data,
          totalCount: response.headers["x-pagination-total-count"],
        });
      })
      .catch((error) => {
        if (error.response.data.code === 2001) {
          const errors = error.response.data.errors;
          toast.error(Object.values(errors[0])[0]);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };

  handleLoadMore = () => {
    this.setState({
      pageSize: this.state.pageSize + 10,
    });
  };

  // Handle message input
  handlMessageInput = (event) => {
    const message = event.target.value.trim();
    this.setState({ message: message });
  };

  //handle message input key down
  handleMessageInputKeyDown = (event) => {
    if (event.key === "Enter") {
      this.handleSendMessage();
    }
  };

  // Handle send message
  handleSendMessage = () => {
    if (this.state.message) {
      const formData = {
        user_id: this.props.userId,
        comment: this.state.message,
      };

      axios
        .post("/comments", formData)
        .then((response) => {
          this.setState({ message: null });
          this.msgInput.current.value = "";
          this.fetchComments();
        })
        .catch((error) => {
          if (error.response.data.code === 2001) {
            const errors = error.response.data.errors;
            toast.error(Object.values(errors[0])[0]);
          } else {
            toast.error(error.response.data.message);
          }
        });
    }
  };

  editComment = (id) => {
    axios
      .get(`/comments/${id}`)
      .then((response) => {
        this.setState({ formValues: response.data, showEditForm: true });
      })
      .catch((error) => {
        if (error.response.data.code === 2001) {
          const errors = error.response.data.errors;
          toast.error(Object.values(errors[0])[0]);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };

  hideEditForm = () => {
    this.setState({ showEditForm: false });
  };

  handleDelete = (id) => {
    this.setState((prevState) => ({
      showDeleteDialogBox: !prevState.showDeleteDialogBox,
      activeComment: id,
    }));
  };

  deleteComment = () => {
    axios
      .delete(`/comments/${this.state.activeComment}`)
      .then((response) => {
        toast.success(response.data.message);
        this.setState({ showDeleteDialogBox: false, activeComment: null });
        this.fetchComments();
      })
      .catch((error) => {
        if (error.response.data.code === 2001) {
          const errors = error.response.data.errors;
          toast.error(Object.values(errors[0])[0]);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };

  render() {
    const { formValues } = this.state;

    const initialValues = {
      comment: formValues ? formValues.comment : null,
    };

    const validationSchema = Yup.object({
      comment: Yup.string().required("Comment is required"),
    });

    const onSubmit = (values) => {
      axios
        .put(`/comments/${formValues.id}`, values)
        .then((response) => {
          toast.success(response.data.message);
          this.fetchComments();
          this.setState({ showEditForm: false });
        })
        .catch((error) => {
          if (error.response.data.code === 2001) {
            const errors = error.response.data.errors;
            toast.error(Object.values(errors[0])[0]);
          } else {
            toast.error(error.response.data.message);
          }
        });
    };

    const newForm = this.state.showEditForm ? (
      <Modal
        show={this.state.showEditForm}
        modalClosed={this.hideEditForm}
        title="Edit Comment"
      >
        <div className="FormContainer ModalForm">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ isSubmitting }) => {
              return (
                <Form>
                  <div className={["FormBody", styles.FormBody].join(" ")}>
                    <FormikControl
                      control="textarea"
                      label="Comment"
                      name="comment"
                      resize="none"
                      rows="6"
                    />
                  </div>
                  <div className="FormActions">
                    <Button
                      btnType="Primary"
                      type="Submit"
                      disabled={isSubmitting}
                    >
                      Save
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Modal>
    ) : null;

    const comments = this.state.comments.map((item, i) => {
      return (
        <div
          className={[styles.Comment, styles[item.sent_by]].join(" ")}
          key={i}
        >
          <div className={styles.CommentContainer}>
            <div className={styles.Content}>
              <div className={styles.Text}>
                {item.comment}
                <span>{item.updated_at ? " (edited)" : null}</span>
              </div>
              <div className={styles.By}>
                <div>{item.admin ? item.admin.name : null}</div>
                <div>
                  {moment(item.created_at).format("DD MMM YYYY, h:mm A")}
                </div>
              </div>
            </div>
            {!item.admin ? (
              <div className={styles.Actions}>
                <div
                  className={styles.Edit}
                  onClick={() => this.editComment(item.id)}
                >
                  <FiEdit2 />
                </div>
                <div
                  className={styles.Delete}
                  onClick={() => this.handleDelete(item.id)}
                >
                  <AiOutlineDelete />
                </div>
              </div>
            ) : null}
          </div>
        </div>
      );
    });

    const loadMoreBtn =
      this.state.pageSize < this.state.totalCount ? (
        <div className={styles.LoadMore}>
          <div onClick={() => this.handleLoadMore()}>Load more...</div>
        </div>
      ) : null;

    return (
      <>
        <Helmet>
          <title>Comments | Lara Capital</title>
        </Helmet>
        <div className="PageHeader">
          <div className="ContentLeft">
            <div className="Title">
              <h1>Comments</h1>
            </div>
          </div>
        </div>
        <div className={[styles.Comments, "PageContent"].join(" ")}>
          <div className={styles.SendMessage}>
            <input
              type="text"
              className="FormControl"
              placeholder="Add a comment..."
              onChange={(event) => this.handlMessageInput(event)}
              value={this.state.newMessage}
              ref={this.msgInput}
              onKeyDown={(event) => this.handleMessageInputKeyDown(event)}
            />
            <div
              className={styles.SendMessageBtn}
              onClick={() => this.handleSendMessage()}
            >
              <IoMdSend />
            </div>
          </div>
          {this.state.comments.length > 0 ? (
            <div className={styles.ListOfComments}>
              {comments}
              {loadMoreBtn}
            </div>
          ) : null}
          <ToastContainer />
          <DialogBox
            show={this.state.showDeleteDialogBox}
            confirm={this.deleteComment}
            cancel={this.handleDelete}
            title="Delete Comment"
            message="Are you sure you want to delete this Comment?"
          />
          {newForm}
        </div>
      </>
    );
  }
}

export default Index;
