import React, { useState } from "react";
import { connect } from "react-redux";
import axios from "../../axios";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import styles from "./SignInForm.module.css";

import FormikControl from "../FormikControl/FormikControl";
import Button from "../Button/Button";
import VerifyOtpForm from "../VerifyOtpForm/VerifyOtpForm";

import { signIn } from "../../store/SignIn/SignIn.Actions";

const SignInForm = (props) => {
  const [showOtpForm, setShowOtpForm] = useState(false);
  const [phone, setPhone] = useState(null);

  const handleVerifyOtpForm = (otp) => {
    props.onSignIn(phone, otp);
  };

  const handleVerifyCancel = () => {
    setShowOtpForm(false);
  };

  const requestOtp = (val) => {
    const data = {
      phone: val,
    };

    axios
      .post("users/login", data)
      .then((response) => {
        toast.success(response.data.message);
        setShowOtpForm(true);
      })
      .catch((error) => {
        if (error.response.data.code === 2001) {
          const errors = error.response.data.errors;
          toast.error(Object.values(errors[0])[0]);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };

  const initialValues = {
    phone: "",
  };

  const validationSchema = Yup.object({
    phone: Yup.string().required("Phone is required"),
  });

  const onSubmit = (values) => {
    setPhone(values.phone);
    requestOtp(values.phone);
  };

  if (props.error) {
    if (props.error.code === 2001) {
      const errors = props.error.errors;
      toast.error(Object.values(errors[0])[0], {
        toastId: "errorMsg",
      });
    } else {
      toast.error(props.error.message, {
        toastId: "errorMsg",
      });
    }
  }

  return (
    <React.Fragment>
      <div
        className={["FormContainer ModalForm", styles.FormContainer].join(" ")}
      >
        {!showOtpForm ? (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {() => (
              <Form className={styles.SignInForm}>
                <div className={["FormBody", styles.FormBody].join(" ")}>
                  <FormikControl
                    control="input"
                    type="text"
                    label="Phone"
                    name="phone"
                  />
                </div>

                <div className="FormActions">
                  <Button btnType="Primary" type="submit" width="W100">
                    Submit
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        ) : (
          <VerifyOtpForm
            handleVerifyOtpForm={handleVerifyOtpForm}
            handleResendOtp={() => requestOtp(phone)}
            handleVerifyCancel={handleVerifyCancel}
          />
        )}
        <ToastContainer />
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    error: state.SignIn.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSignIn: (phone, otp) => dispatch(signIn(phone, otp)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignInForm);
