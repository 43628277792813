import React, { useState, useEffect, useCallback, useRef } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import axios from "../../axios";
import moment from "moment";
import { Helmet } from "react-helmet";
import { DateRangePicker } from "react-date-range";

import styles from "./Index.module.css";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

import Button from "../../components/Button/Button";
import Spinner from "../../components/Spinner/Spinner";
import Modal from "../../components/Modal/Modal";
import SearchBox from "../../components/SearchBox/SearchBox";

import PrevIcon from "../../assets/images/left-arrow-icon.svg";
import FirstIcon from "../../assets/images/double-left-arrow-icon.svg";
import NextIcon from "../../assets/images/right-arrow-icon.svg";
import LastIcon from "../../assets/images/double-right-arrow-icon.svg";
import CalendarIcon from "../../assets/images/calendar-circle-icon.svg";
import CloseIcon from "../../assets/images/close-circle-icon.svg";

const Table = ({
  columns,
  data,
  onSort,
  fetchData,
  filterDates,
  pageCount: controlledPageCount,
  recordCount,
  setSearchKeyword,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        sortBy: [
          {
            id: "created_at",
            desc: true,
          },
        ],
      },
      manualPagination: true,
      manualSortBy: true,
      autoResetPage: false,
      autoResetSortBy: false,
      pageCount: controlledPageCount,
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    onSort({ sortBy, pageIndex, pageSize });
    fetchData({ pageIndex, pageSize, sortBy, filterDates });
  }, [fetchData, pageIndex, pageSize, onSort, sortBy, filterDates]);

  const searchTable = (event) => {
    if (event.key === "Enter") {
      setSearchKeyword(event.target.value);
    }
  };

  return (
    <>
      <div className="TableHeader">
        <div className="SearchTable">
          <SearchBox
            placeholder="Search by REF ID"
            onKeyPress={(event) => searchTable(event)}
          />
        </div>
      </div>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {recordCount > 0 ? (
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td colSpan="10000">No records found!</td>
            </tr>
          </tbody>
        )}
      </table>
      {recordCount > 10 ? (
        <div className="TableFooter">
          <div className="TableRecordsCount">
            <div className="PageSize">
              <label>Show</label>
              <select
                className="FormControl"
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[10, 20, 30, 40, 50, 100, 500].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
            </div>
            <div className="RecordsStartEnd">
              Showing {page.length} of {recordCount}
            </div>
          </div>
          <div className="TablePagination">
            <div className="PageCountStat">
              Page
              <span>
                {pageIndex + 1} of {pageCount}
              </span>
            </div>
            <div>|</div>
            <div className="GoToPage">
              <label>Go to page</label>
              <input
                className="FormControl"
                type="number"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
              />
            </div>
            <div className="PaginationNav">
              <Button
                type="button"
                btnType="Default"
                clicked={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                <img src={FirstIcon} alt="First Page" />
              </Button>
              <Button
                type="button"
                btnType="Default"
                clicked={() => previousPage()}
                disabled={!canPreviousPage}
              >
                <img src={PrevIcon} alt="Previous Page" />
              </Button>
              <Button
                type="button"
                btnType="Default"
                clicked={() => nextPage()}
                disabled={!canNextPage}
              >
                <img src={NextIcon} alt="Next Page" />
              </Button>
              <Button
                type="button"
                btnType="Default"
                clicked={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                <img src={LastIcon} alt="Last Page" />
              </Button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

const Index = () => {
  const [showCalendarModal, setShowCalendarModal] = useState(false);

  const handleCalendarModal = () => {
    setShowCalendarModal(!showCalendarModal);
  };

  const handleDateSelect = (item) => {
    setState([item]);
  };

  const submitFilterDates = () => {
    const startDate = moment(state[0].startDate).format("YYYY-MM-DD");
    const endDate = moment(state[0].endDate).format("YYYY-MM-DD");

    setFitlerDates({
      startDate: startDate,
      endDate: endDate,
    });

    handleCalendarModal();
    setTableId(tableId + 1);
  };

  const [filterDates, setFitlerDates] = useState({
    startDate: "",
    endDate: "",
  });

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const calendar = showCalendarModal ? (
    <Modal
      show={showCalendarModal}
      modalClosed={handleCalendarModal}
      width="625px"
      className="CalendarModal"
    >
      <DateRangePicker
        onChange={(item) => handleDateSelect(item.selection)}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={1}
        ranges={state}
        direction="vertical"
      />
      <div className={styles.SubmitDatesBtn}>
        <Button btnType="Primary" clicked={submitFilterDates}>
          Submit
        </Button>
      </div>
    </Modal>
  ) : null;

  const resetFilterDates = () => {
    setFitlerDates({
      startDate: "",
      endDate: "",
    });
  };

  const [tableId, setTableId] = useState(1);
  const columns = React.useMemo(
    () => [
      {
        Header: "REF ID",
        accessor: "ref_id",
        disableSortBy: true,
      },
      {
        Header: "Status",
        accessor: "status.name",
        disableSortBy: true,
      },
      {
        Header: "Requested On",
        id: "created_at",
        accessor: (d) => moment(d.created_at).format("DD MMM YYYY, hh:mm A"),
      },
      {
        Header: "Paid at",
        id: "paid_at",
        accessor: (d) =>
          d.paid_at ? moment(d.paid_at).format("DD MMM YYYY, hh:mm A") : null,
      },
    ],
    []
  );

  // We'll start our table without any data
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [recordCount, setRecordCount] = useState(0);
  const [startRecord, setStartRecord] = useState(0);
  const [endRecord, setEndRecord] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const fetchIdRef = useRef(0);

  const loadData = (
    pageSize,
    pageIndex,
    sortBy,
    searchKeyword,
    filterDates
  ) => {
    let orderBy = {
      order: "created_at",
      by: "DESC",
    };

    if (sortBy.length) {
      orderBy = {
        order: sortBy[0].id,
        by: sortBy[0].desc ? "DESC" : "ASC",
      };
    }

    axios
      .get(
        `/invoices?per-page=` +
          pageSize +
          "&page=" +
          (pageIndex + 1) +
          "&keyword=" +
          searchKeyword +
          "&start_date=" +
          filterDates.startDate +
          "&end_date=" +
          filterDates.endDate +
          "&order=" +
          orderBy.order +
          "&by=" +
          orderBy.by
      )
      .then((response) => {
        setData(response.data);
        setPageCount(response.headers["x-pagination-page-count"]);
        setRecordCount(response.headers["x-pagination-total-count"]);
        setStartRecord(response.headers["x-pagination-start-record"]);
        setEndRecord(response.headers["x-pagination-end-record"]);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const fetchData = useCallback(
    ({ pageSize, pageIndex, sortBy, filterDates }) => {
      const fetchId = ++fetchIdRef.current;

      setLoading(true);

      if (fetchId === fetchIdRef.current) {
        loadData(pageSize, pageIndex, sortBy, searchKeyword, filterDates);
      }
    },
    [searchKeyword]
  );

  const handleSort = useCallback(() => {
    setLoading(true);
  }, []);

  return (
    <>
      <Helmet>
        <title>Invoices | Lara Capital</title>
      </Helmet>
      <div className="PageHeader">
        <div className="ContentLeft">
          <div className="Title">
            <h1>Invoices</h1>
          </div>
        </div>
      </div>

      <div className="PageContent DataTable">
        <div className={styles.Ledger}>
          <div className={styles.Header}>
            <div className={styles.TableActions}>
              <div className={styles.DateRangeFilter}>
                {filterDates.startDate && filterDates.endDate ? (
                  <div className={styles.SelectedFilterDates}>
                    <div className={styles.Date}>
                      {moment(filterDates.startDate).format("DD MMM YYYY") +
                        " - " +
                        moment(filterDates.endDate).format("DD MMM YYYY")}
                    </div>
                    <img
                      src={CloseIcon}
                      alt="Reset Date Filter"
                      onClick={() => resetFilterDates()}
                    />
                  </div>
                ) : null}
                <img
                  src={CalendarIcon}
                  className={styles.CalendarIcon}
                  alt="Calendar"
                  onClick={() => handleCalendarModal()}
                />
              </div>
              {calendar}
            </div>
          </div>
          <div className="ScrollableTable">
            <Table
              key={tableId}
              columns={columns}
              data={data}
              onSort={handleSort}
              fetchData={fetchData}
              filterDates={filterDates}
              loading={loading}
              pageCount={pageCount}
              recordCount={recordCount}
              startRecord={startRecord}
              endRecord={endRecord}
              setSearchKeyword={setSearchKeyword}
            />
          </div>
        </div>
      </div>
      <Spinner isLoading={loading} />
    </>
  );
};

export default Index;
