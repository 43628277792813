import React from "react";

import styles from "./Spinner.module.css";

const Spinner = (props) => {
  const spinner = props.isLoading ? (
    <React.Fragment>
      <div className={styles.LdsRing}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      {/* <div className={styles.SpinnerOverlay}></div> */}
    </React.Fragment>
  ) : null;

  return spinner;
};

export default Spinner;
