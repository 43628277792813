import React, { Component, createRef } from "react";
import axios from "../../../axios";
import { Helmet } from "react-helmet";
import moment from "moment";
import { IoMdSend } from "react-icons/io";

import styles from "./ViewTicket.module.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class ViewTicket extends Component {
  constructor() {
    super();
    this.msgInput = createRef();
  }

  state = {
    data: null,
    replies: [],
    message: null,
    pageSize: 10,
    totalCount: 0,
    isLoading: false,
  };

  componentDidMount() {
    this.fetchTicketDetails();
    this.fetchReplies();

    setInterval(() => {
      this.fetchTicketDetails();
      this.fetchReplies();
    }, 5000);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.pageSize !== prevState.pageSize) {
      this.fetchReplies();
    }
  }

  fetchTicketDetails = () => {
    const { id } = this.props.match.params;

    axios
      .get(`/tickets/${id}`)
      .then((response) => {
        this.setState({ data: response.data });
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  fetchReplies = () => {
    const { id } = this.props.match.params;
    axios
      .get(
        `/tickets-messages?ticket=${id}&page=1&per-page=${this.state.pageSize}`
      )
      .then((response) => {
        this.setState({
          replies: response.data,
          totalCount: response.headers["x-pagination-total-count"],
        });
      })
      .catch((error) => {
        if (error.response.data.code === 2001) {
          const errors = error.response.data.errors;
          toast.error(Object.values(errors[0])[0]);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };

  handleLoadMore = () => {
    this.setState({
      pageSize: this.state.pageSize + 10,
    });
  };

  // Handle message input
  handlMessageInput = (event) => {
    const message = event.target.value.trim();
    this.setState({ message: message });
  };

  //handle message input key down
  handleMessageInputKeyDown = (event) => {
    if (event.key === "Enter") {
      this.handleSendMessage();
    }
  };

  // Handle send message
  handleSendMessage = () => {
    if (this.state.message) {
      const { id } = this.props.match.params;

      const formData = {
        ticket_id: id,
        message: this.state.message,
      };

      axios
        .post("/tickets-messages", formData)
        .then((response) => {
          this.setState({ message: null });
          this.msgInput.current.value = "";
          this.fetchReplies();
        })
        .catch((error) => {
          if (error.response.data.code === 2001) {
            const errors = error.response.data.errors;
            toast.error(Object.values(errors[0])[0]);
          } else {
            toast.error(error.response.data.message);
          }
        });
    }
  };

  render() {
    const { data } = this.state;

    const replies = this.state.replies.map((item, i) => {
      return (
        <div className={[styles.Reply, styles[item.sent_by]].join(" ")} key={i}>
          <div className={styles.Content}>
            <div>{item.message}</div>
            <div>{moment(item.created_at).format("DD MMM YYYY, h:mm A")}</div>
          </div>
        </div>
      );
    });

    const loadMoreBtn =
      this.state.pageSize < this.state.totalCount ? (
        <div className={styles.LoadMore}>
          <div onClick={() => this.handleLoadMore()}>Load more...</div>
        </div>
      ) : null;

    return (
      <div className={styles.View}>
        <Helmet>
          <title>Support | Lara Capital</title>
        </Helmet>
        <div className="PageHeader">
          <div className="ContentLeft">
            <div className="Title">
              <h1>Support</h1>
            </div>
          </div>
        </div>
        {data ? (
          <div className={["PageContent", styles.TicketOverview].join(" ")}>
            <div className={styles.DetailOverview}>
              <div className={styles.Detail}>
                <div>REF ID</div>
                <div>{data.ref_id}</div>
              </div>
              <div className={styles.Detail}>
                <div>Subject</div>
                <div>{data.subject}</div>
              </div>
              <div className={styles.Detail}>
                <div>Raised On</div>
                <div>
                  {moment(data.created_atS).format("DD MMM YYYY, h:mm A")}
                </div>
              </div>
              <div className={styles.Detail}>
                <div>Status</div>
                <div>{data.status.name}</div>
              </div>
              <div className={styles.Detail}>
                <div>Message</div>
                <div>{data.message}</div>
              </div>
            </div>
          </div>
        ) : null}

        {this.state.replies.length > 0 ? (
          <div className={["PageContent", styles.Replies].join(" ")}>
            <h2>Replies</h2>
            {data && data.status.id === 2 ? (
              <div className={styles.SendMessage}>
                <input
                  type="text"
                  className="FormControl"
                  placeholder="Send a message..."
                  onChange={(event) => this.handlMessageInput(event)}
                  value={this.state.newMessage}
                  ref={this.msgInput}
                  onKeyDown={(event) => this.handleMessageInputKeyDown(event)}
                />
                <div
                  className={styles.SendMessageBtn}
                  onClick={() => this.handleSendMessage()}
                >
                  <IoMdSend />
                </div>
              </div>
            ) : null}
            <div className={styles.ListOfReplies}>
              {replies}
              {loadMoreBtn}
            </div>
          </div>
        ) : null}
        <ToastContainer />
      </div>
    );
  }
}

export default ViewTicket;
