import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import axios from "../../../axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import styles from "./NewTicket.module.css";

import Modal from "../../../components/Modal/Modal";
import Button from "../../../components/Button/Button";
import FormikControl from "../../../components/FormikControl/FormikControl";

const NewTicket = (props) => {
  const [formId, setFormId] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const subjectsList = [
    { value: "Reports", label: "Reports" },
    { value: "Bank Updation", label: "Bank Updation" },
    { value: "Nominee Updation", label: "Nominee Updation" },
    { value: "Deposit Certificate", label: "Deposit Certificate" },
    { value: "Others", label: "Others" },
  ];

  const initialValues = {
    subject: "",
    message: "",
  };

  const validationSchema = Yup.object({
    subject: Yup.string().required("Subject is required").trim(),
    message: Yup.string().required("Message is required").trim(),
  });

  const onSubmit = (values) => {
    setIsLoading(true);

    const formData = {
      subject: values.subject,
      message: values.message,
    };

    axios
      .post(`/tickets`, formData)
      .then((response) => {
        toast.success(response.data.message);
        setFormId(formId + 1);
        props.handleModalClose();
        props.reloadTicketsTable();
      })
      .catch((error) => {
        if (error.response.data.code === 2001) {
          const errors = error.response.data.errors;
          toast.error(Object.values(errors[0])[0]);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className={styles.NewTicket}>
      <Modal
        key={formId}
        show={props.show}
        modalClosed={() => props.handleModalClose()}
        title="Raise a Support Ticket"
      >
        <div className="FormContainer ModalForm">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ setFieldValue }) => (
              <Form>
                <div className={["FormBody", styles.FormBody].join(" ")}>
                  <FormikControl
                    control="select"
                    label="Subject *"
                    name="subject"
                    options={subjectsList}
                    value=""
                    setFieldValue={setFieldValue}
                  />

                  <FormikControl
                    control="textarea"
                    label="Message *"
                    name="message"
                  />
                </div>
                <div className="FormActions">
                  <Button btnType="Primary" type="Submit" isLoading={isLoading}>
                    Submit
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default NewTicket;
