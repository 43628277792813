import React, { Component } from "react";
import axios from "../../axios";
import { Helmet } from "react-helmet";
import { IoMdAddCircleOutline } from "react-icons/io";
import { MdOutlineSupportAgent } from "react-icons/md";

import styles from "./Support.module.css";

import Spinner from "../../components/Spinner/Spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Tickets from "./Tickets/Tickets";
import NewTicket from "./NewTicket/NewTicket";

class Support extends Component {
  state = {
    stats: null,
    isLoading: false,
    showNewTicketModal: false,
    ticketsTableId: 1,
  };

  componentDidMount() {
    this.fetchTicketStats();
  }

  fetchTicketStats = () => {
    axios
      .get("/tickets/stats")
      .then((response) => {
        this.setState({ stats: response.data });
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  handleNewTicket = () => {
    this.setState({ showNewTicketModal: !this.state.showNewTicketModal });
  };

  reloadTicketsTable = () => {
    this.setState({ ticketsTableId: this.state.ticketsTableId + 1 });
    this.fetchTicketStats();
  };

  handleCallRequest = () => {
    axios
      .post("/call-requests")
      .then((response) => {
        toast.success(response.data.message);
      })
      .catch((error) => {
        if (error.response.data.code === 2001) {
          const errors = error.response.data.errors;
          toast.error(Object.values(errors[0])[0]);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };

  render() {
    const { stats } = this.state;

    return (
      <div className={styles.View}>
        <Helmet>
          <title>Support | Lara Capital</title>
        </Helmet>
        <div className="PageHeader">
          <div className="ContentLeft">
            <div className="Title">
              <h1>Support</h1>
            </div>
          </div>
        </div>
        <div className={["PageContent", styles.StatsContainer].join(" ")}>
          <div className={styles.Stats}>
            <div className={styles.Stat}>
              <div>Raised Support Tickets</div>
              <div>{stats ? stats.total_tickets : ""}</div>
            </div>
            <div className={styles.Stat}>
              <div>Open Tickets</div>
              <div>{stats ? stats.open_tickets : ""}</div>
            </div>
            <div className={styles.Stat}>
              <div>Raise a new Ticket</div>
              <div className={styles.RaiseTicket}>
                <IoMdAddCircleOutline onClick={() => this.handleNewTicket()} />
              </div>
            </div>
            <div className={[styles.Stat, styles.SupportCall].join(" ")}>
              <div onClick={() => this.handleCallRequest()}>
                Request for a Support Call
              </div>
              <div>
                <MdOutlineSupportAgent
                  onClick={() => this.handleCallRequest()}
                />
              </div>
            </div>
          </div>
        </div>

        {stats && stats.total_tickets > 0 ? (
          <div className={["PageContent", styles.TicketsContainer].join(" ")}>
            <div className={styles.Tickets}>
              <Tickets key={this.state.ticketsTableId} />
            </div>
          </div>
        ) : null}
        <NewTicket
          show={this.state.showNewTicketModal}
          handleModalClose={this.handleNewTicket}
          reloadTicketsTable={this.reloadTicketsTable}
        />
        <Spinner isLoading={this.state.isLoading} />
        <ToastContainer />
      </div>
    );
  }
}

export default Support;
