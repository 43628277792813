import React, { Component } from "react";
import { Switch } from "react-router-dom";
import { connect } from "react-redux";
import { authCheck, logout } from "./store/SignIn/SignIn.Actions";

import "./App.css";

import SignInLayout from "./hoc/Layouts/SignInLayout/SignInLayout";
import MainLayout from "./hoc/Layouts/MainLayout/MainLayout";

import SignIn from "./containers/SignIn/SignIn";
import MyProfile from "./containers/MyProfile/MyProfile";
import Ledgers from "./containers/Ledgers/Index";
import Products from "./containers/Products/Index";
import Transactions from "./containers/Transactions/Index";
import Comments from "./containers/Comments/Index";
import Offers from "./containers/Offers/Index";
import Invoices from "./containers/Invoices/Index";
import Statements from "./containers/Statements/Index";
import Support from "./containers/Support/Support";
import ViewTicket from "./containers/Support/ViewTicket/ViewTicket";

class App extends Component {
  componentDidMount() {
    this.props.onAutoSignIn();
  }

  render() {
    let routes = (
      <Switch>
        <SignInLayout path="/" component={SignIn} />
      </Switch>
    );

    if (this.props.isAuthenticated) {
      routes = (
        <Switch>
          <MainLayout path="/" exact component={Ledgers} />
          <MainLayout path="/my-profile" exact component={MyProfile} />
          <MainLayout path="/products" exact component={Products} />
          <MainLayout path="/transactions" exact component={Transactions} />
          <MainLayout path="/comments" exact component={Comments} />
          <MainLayout path="/support" exact component={Support} />
          <MainLayout path="/offers" exact component={Offers} />
          <MainLayout path="/invoices" exact component={Invoices} />
          <MainLayout path="/statements" exact component={Statements} />
          <MainLayout
            path="/support/ticket/:id/view"
            exact
            component={ViewTicket}
          />
          <SignInLayout path="/" exact component={SignIn} />
          <MainLayout path="/logout" component={logout} />
        </Switch>
      );
    }

    return routes;
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.SignIn.isAuthenticated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAutoSignIn: () => dispatch(authCheck()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
