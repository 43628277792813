import React from "react";
import * as XLSX from "xlsx";
import generatePDF, { Margin } from "react-to-pdf";
import { BiExport } from "react-icons/bi";
import { FaFileExcel, FaFilePdf } from "react-icons/fa";

import styles from "./Export.module.css";

const Export = (props) => {
  const { data, activeBrokerName, type, targetRef } = props;

  const options = {
    filename: type + "-" + activeBrokerName + ".pdf",
    page: {
      // margin is in MM, default is Margin.NONE = 0
      margin: Margin.SMALL,
    },
  };

  const handleExport = (fileType) => {
    if (fileType === "xlsx") {
      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, activeBrokerName);
      XLSX.writeFile(workbook, `${type}-${activeBrokerName}.xlsx`);
    } else {
      generatePDF(targetRef, options);
    }
  };

  return (
    <div className={styles.Export}>
      <div className={styles.ExportBtn}>
        <BiExport />
        Export
      </div>
      <div className={styles.DropdownMenu}>
        <div className={styles.Excel} onClick={() => handleExport("xlsx")}>
          <FaFileExcel /> Excel
        </div>
        <div className={styles.Pdf} onClick={() => handleExport("pdf")}>
          <FaFilePdf />
          PDF
        </div>
      </div>
    </div>
  );
};

export default Export;
