import React, { Component } from "react";
import axios from "../../axios";
import { Helmet } from "react-helmet";
import moment from "moment";

import styles from "./MyProfile.module.css";

import Spinner from "../../components/Spinner/Spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class MyProfile extends Component {
  state = {
    user: null,
    dematAccounts: null,
    bankAccounts: null,
    isLoading: false,
  };

  componentDidMount() {
    this.fetchUserDetails();
    this.fetchDematAccounts();
    this.fetchBankAccounts();
  }

  fetchUserDetails = () => {
    axios
      .get("/users")
      .then((response) => {
        this.setState({ user: response.data });
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  fetchDematAccounts = () => {
    axios
      .get("/broker-accounts")
      .then((response) => {
        this.setState({ dematAccounts: response.data });
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  fetchBankAccounts = () => {
    axios
      .get("/banks")
      .then((response) => {
        this.setState({ bankAccounts: response.data });
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  render() {
    const { user, dematAccounts, bankAccounts } = this.state;

    let dematAccountsList = null;
    if (dematAccounts) {
      dematAccountsList = dematAccounts.map((item, i) => {
        return (
          <tr key={i}>
            <td>{item.broker.name}</td>
            <td>{item.dp_id}</td>
            <td>{item.client_id}</td>
            <td>{item.poa}</td>
          </tr>
        );
      });
    }

    let bankAccountsList = null;
    if (bankAccounts) {
      bankAccountsList = bankAccounts.map((item, i) => {
        return (
          <tr key={i}>
            <td>{item.bank_name}</td>
            <td>{item.account_no}</td>
            <td>{item.ifsc}</td>
          </tr>
        );
      });
    }

    return (
      <div className={styles.View}>
        <Helmet>
          <title>My Profile | Lara Capital</title>
        </Helmet>
        <div className="PageHeader">
          <div className="ContentLeft">
            <div className="Title">
              <h1>My Profile</h1>
            </div>
          </div>
        </div>
        <div className="PageContent Type1">
          {user ? (
            <div className={styles.DetailsContainer}>
              <div className={styles.DetailOverview}>
                <div className={styles.Detail}>
                  <div>User ID</div>
                  <div>{user.user_id}</div>
                </div>
                <div className={styles.Detail}>
                  <div>Name</div>
                  <div>{user.first_name + " " + user.last_name}</div>
                </div>
                <div className={styles.Detail}>
                  <div>Email</div>
                  <div>{user.email}</div>
                </div>
                <div className={styles.Detail}>
                  <div>Phone</div>
                  <div>{user.phone}</div>
                </div>
                <div className={styles.Detail}>
                  <div>Date of Birth</div>
                  <div>{moment(user.date_of_birth).format("DD MMM YYYY")}</div>
                </div>
                <div className={styles.Detail}>
                  <div>PAN</div>
                  <div>{user.pan}</div>
                </div>
                <div className={styles.Detail}>
                  <div>Address</div>
                  <div>{user.address}</div>
                </div>
                <div className={styles.Detail}>
                  <div>Date of Account Opening</div>
                  <div>{moment(user.created_at).format("DD MMM YYYY")}</div>
                </div>
                <div className={styles.Detail}>
                  <div>Last Login</div>
                  <div>
                    {user.last_login
                      ? moment(user.last_login).format("DD MMM YYYY, h:mm A")
                      : "NA"}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <div className="PageContent DataTable Type1">
          <h2 className={styles.ContentHeader}>Demat Accounts</h2>
          {dematAccounts ? (
            <table>
              <thead>
                <tr>
                  <th>Broker Name</th>
                  <th>DP ID</th>
                  <th>Client ID</th>
                  <th>POA</th>
                </tr>
              </thead>
              <tbody>{dematAccountsList}</tbody>
            </table>
          ) : (
            <div>You don't have any Demat Accounts</div>
          )}
        </div>
        <div className="PageContent DataTable Type1">
          <h2 className={styles.ContentHeader}>Bank Accounts</h2>
          {dematAccounts ? (
            <table>
              <thead>
                <tr>
                  <th>Bank Name</th>
                  <th>Account No</th>
                  <th>IFSC</th>
                </tr>
              </thead>
              <tbody>{bankAccountsList}</tbody>
            </table>
          ) : (
            <div>You don't have any Bank Accounts</div>
          )}
        </div>
        <Spinner isLoading={this.state.isLoading} />
        <ToastContainer />
      </div>
    );
  }
}

export default MyProfile;
