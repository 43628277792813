import React, { useState } from "react";
import { Field, ErrorMessage } from "formik";

import styles from "./Password.module.css";

import ErrorMsg from "../../ErrorMsg/ErrorMsg";
import togglePasswordIcon from "../../../assets/images/show-password.svg";

const Password = (props) => {
  const { label, name, type, ...rest } = props;

  const [showHidePassword, togglePasswordHandler] = useState(false);
  
  const togglePasswordBtn = (
    <img
      src={togglePasswordIcon}
      alt="Show Password"
      className={styles.PasswordToggleBtn}
      onClick={() => togglePasswordHandler(!showHidePassword)}
    />
  );

  return (
    <div className="FormGroup">
      <label htmlFor={name} className="ControlLabel">{label}</label>
      <Field className={styles.FormControl} type={showHidePassword ? "text" : "password"} id={name} name={name} autoComplete="off" {...rest} />
      {togglePasswordBtn}
      <ErrorMessage name={name} component={ErrorMsg} />
    </div>
  );
};

export default Password;
