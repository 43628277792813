import React, { Component } from "react";
import { Helmet } from "react-helmet";

import styles from "./SignIn.module.css";

import SignInForm from "../../components/SignInForm/SignInForm";

class SignIn extends Component {
  render() {
    return (
      <div className={styles.SignIn}>
        <Helmet>
          <title>Sign In | Lara Capital</title>
        </Helmet>

        <SignInForm />
      </div>
    );
  }
}

export default SignIn;
