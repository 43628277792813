import jwt_decode from "jwt-decode";
import SignInTypes from "./SignIn.Types";
import axios from "../../axios";
import axiosRefresh from "../../axiosRefresh";

export const signInStart = () => ({
  type: SignInTypes.SIGN_IN_START,
});

export const signInSuccess = (token, userId) => ({
  type: SignInTypes.SIGN_IN_SUCCESS,
  token: token,
  userId: userId,
});

export const signInFailure = (error) => ({
  type: SignInTypes.SIGN_IN_FAILURE,
  error: error,
});

export const signIn = (phone, otp) => {
  return (dispatch) => {
    dispatch(signInStart());
    const signInCreds = {
      phone: phone,
      otp: otp,
    };
    axios
      .post("/users/verify-login", signInCreds)
      .then((response) => {
        const jwtToken = jwt_decode(response.data.access_token);
        const expirationDate = new Date(jwtToken.exp * 1000);

        localStorage.setItem("userId", response.data.user_id);
        localStorage.setItem("token", response.data.access_token);
        localStorage.setItem("refreshToken", response.data.refresh_token);
        localStorage.setItem("expirationDate", expirationDate);
        localStorage.setItem("name", response.data.name);
        localStorage.setItem("dateOfBirth", response.data.date_of_birth);

        const expiresIn = jwtToken.exp - Math.floor(Date.now() / 1000);
        dispatch(checkAuthTimeout(expiresIn));

        dispatch(
          signInSuccess(response.data.access_token, response.data.user_id)
        );

        dispatch(checkActiveOffer());
      })
      .catch((error) => {
        dispatch(signInFailure(error.response.data));
      });
  };
};

export const refreshToken = () => {
  return (dispatch) => {
    axiosRefresh
      .post("/users/refresh-token")
      .then((response) => {
        const jwtToken = jwt_decode(response.data.access_token);
        const expirationDate = new Date(jwtToken.exp * 1000);

        localStorage.setItem("userId", response.data.user_id);
        localStorage.setItem("token", response.data.access_token);
        localStorage.setItem("refreshToken", response.data.refresh_token);
        localStorage.setItem("expirationDate", expirationDate);
        localStorage.setItem("name", response.data.name);

        const expiresIn = jwtToken.exp - Math.floor(Date.now() / 1000);
        dispatch(checkAuthTimeout(expiresIn));

        dispatch(signInSuccess(response.data.access_token, response.data.id));
      })
      .catch(() => {
        dispatch(logout());
      });
  };
};

export const checkAuthTimeout = (expirationTime) => {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(refreshToken());
    }, (expirationTime - 60) * 1000);
  };
};

export const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("expirationDate");
  localStorage.removeItem("userId");
  localStorage.removeItem("name");
  localStorage.removeItem("activeOffer");
  localStorage.removeItem("offerPopupShowed");

  return {
    type: SignInTypes.LOGOUT_SESSION,
  };
};

export const authCheck = () => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
    if (!token) {
      dispatch(logout());
    } else {
      const expirationDate = new Date(localStorage.getItem("expirationDate"));

      if (expirationDate < new Date()) {
        dispatch(logout());
      } else {
        dispatch(signInSuccess(token, userId));
        dispatch(
          checkAuthTimeout(
            expirationDate.getSeconds() - new Date().getSeconds()
          )
        );
      }
    }
  };
};

const checkActiveOffer = () => {
  axios
    .get("/offers/active")
    .then((response) => {
      localStorage.setItem("activeOffer", JSON.stringify(response.data));
      localStorage.setItem("offerPopupShowed", "false");
    })
    .catch((error) => {
      console.log("activeOffer", error);
    });
};
