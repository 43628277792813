import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "../../axios";
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import styles from "./Index.module.css";

import Export from "../../components/Export/Export";

const Index = () => {
  const [dematAccounts, setDematAccounts] = useState(null);
  const [activeBroker, setActiveBroker] = useState(null);
  const [activeBrokerName, setActiveBrokerName] = useState(null);
  const [data, setData] = useState([]);
  const [exportData, setExportData] = useState([]);

  const targetRef = useRef();

  const fetchDematAccounts = useCallback(() => {
    axios
      .get("/broker-accounts")
      .then((response) => {
        setDematAccounts(response.data);
        if (response.data.length > 0) {
          setActiveBroker(response.data[0].broker.id);
          setActiveBrokerName(response.data[0].broker.name);
        }
        fetchData(response.data[0].broker.id);
      })
      .catch((error) => {
        if (error.response.data.code === 2001) {
          const errors = error.response.data.errors;
          toast.error(Object.values(errors[0])[0]);
        } else {
          toast.error(error.response.data.message);
        }
      });
  }, []);

  const fetchData = (id) => {
    axios
      .get(`/ledger?broker_id=${id}`)
      .then((response) => {
        setData(response.data);
        formatExportData(response.data);
      })
      .catch((error) => {
        if (error.response.data.code === 2001) {
          const errors = error.response.data.errors;
          toast.error(Object.values(errors[0])[0]);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };

  const formatExportData = (data) => {
    const formattedData = [];
    data.forEach((item) => {
      formattedData.push({
        Title: item.heading,
        Amount: item.amount,
      });
    });

    setExportData(formattedData);
  };

  const handleTab = (id, name) => {
    setActiveBroker(id);
    setActiveBrokerName(name);
    fetchData(id);
  };

  useEffect(() => {
    if (!data.length) {
      fetchDematAccounts();
    }
  }, [fetchDematAccounts, data]);

  let brokerAccountTabs = null;
  if (dematAccounts) {
    brokerAccountTabs = dematAccounts.map((item, i) => {
      return (
        <li
          key={i}
          className={activeBroker === item.broker.id ? "Active" : null}
          onClick={() => handleTab(item.broker.id, item.broker.name)}
        >
          {item.broker.name}
        </li>
      );
    });
  }

  return (
    <>
      <Helmet>
        <title>Ledgers | Lara Capital</title>
      </Helmet>
      <div className="PageHeader">
        <div className="ContentLeft">
          <div className="Title">
            <h1>Ledgers</h1>
          </div>
        </div>
      </div>

      <div className="PageContent PageNavigation">
        <div className="NavigationMenu">
          <ul>{brokerAccountTabs}</ul>
        </div>
        <div className="PageActions">
          {data.length ? (
            <Export
              data={exportData}
              activeBrokerName={activeBrokerName}
              targetRef={targetRef}
              type="Ledger"
            />
          ) : null}
        </div>
      </div>
      <div className="PageContent DataTable">
        {data.length ? (
          <div className={styles.Ledger} ref={targetRef}>
            <table className="type-1">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item) => {
                  return (
                    <tr>
                      <td>{item.heading}</td>
                      <td>Rs. {item.amount}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div>No records found</div>
        )}
      </div>
      <ToastContainer />
    </>
  );
};

export default Index;
