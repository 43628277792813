import React, { useState, useEffect, useCallback } from "react";
import { Route } from "react-router-dom";
import axios from "../../../axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import styles from "./MainLayout.module.css";

import Header from "../../../components/Header/Header";
import MainNav from "../../../components/Navigations/MainNav/MainNav";
import Button from "../../../components/Button/Button";
import Modal from "../../../components/Modal/Modal";

const MainLayout = (props) => {
  const [showSideNav, setSideNav] = useState(false);
  const [showOfferModal, setShowOfferModal] = useState(false);
  const [activeOffer, setActiveOffer] = useState(null);

  const sideNavToggleHandler = () => {
    setSideNav(!showSideNav);
  };

  const sideNavCloseHandler = () => {
    setSideNav(false);
  };

  const handleOfferModal = useCallback(() => {
    setShowOfferModal(!showOfferModal);
    localStorage.setItem("offerPopupShowed", "true");
  });

  const handleShowInterest = () => {
    axios
      .post(`/offers/${activeOffer.id}/show-interest`)
      .then((response) => {
        handleOfferModal();
        toast.success(response.data.message);
      })
      .catch((error) => {
        if (error.response.data.code === 2001) {
          const errors = error.response.data.errors;
          toast.error(Object.values(errors[0])[0]);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };

  useEffect(() => {
    setTimeout(() => {
      const activeOffer = localStorage.getItem("activeOffer");
      const offerPopupShowed = localStorage.getItem("offerPopupShowed");
      if (activeOffer && offerPopupShowed == "false") {
        setActiveOffer(JSON.parse(activeOffer));
        handleOfferModal();
      }
    }, 3000);
  }, [handleOfferModal]);

  const offerPopup =
    setShowOfferModal && activeOffer ? (
      <div className={styles.OfferPopup}>
        <Modal
          show={showOfferModal}
          modalClosed={handleOfferModal}
          width="960px"
          title="Offer"
        >
          <div className={styles.OfferContent}>
            <h2>{activeOffer.title}</h2>
            <p>{activeOffer.message}</p>
            <Button btnType="Primary" clicked={handleShowInterest}>
              I'm Interested
            </Button>
          </div>
        </Modal>
      </div>
    ) : null;

  return (
    <div className={styles.Wrapper}>
      <div className={styles.Container}>
        <MainNav showSideNav={showSideNav} closeSideNav={sideNavCloseHandler} />
        <div className={styles.PageContainer}>
          <Header sideNavToggleHandler={sideNavToggleHandler} />
          <Route component={props.component} {...props} />
        </div>
      </div>
      {offerPopup}
      <ToastContainer />
    </div>
  );
};

export default MainLayout;
