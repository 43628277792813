import React from "react";
import { Link } from "react-router-dom";
import { RiUser3Line, RiCoupon3Fill } from "react-icons/ri";
import { FaRegUserCircle, FaFileInvoiceDollar, FaRegComments } from "react-icons/fa";
import { BiSupport, BiBookContent } from "react-icons/bi";
import { TbReportMoney } from "react-icons/tb";
import { AiTwotoneBuild } from "react-icons/ai";
import { AiOutlineTransaction } from "react-icons/ai";

import styles from "./MainNav.module.css";

import NavItem from "./NavItem/NavItem";

import Logo from "../../../assets/images/logo.svg";

const MainNav = (props) => {
  let backDrop = null;
  let toggleClass = styles.Close;

  if (props.showSideNav) {
    toggleClass = styles.Open;
    backDrop = (
      <div className={styles.BackDrop} onClick={props.closeSideNav}></div>
    );
  }

  return (
    <>
      <div className={[styles.MainNav, toggleClass].join(" ")}>
        <div className={styles.Logo}>
          <Link to="/">
            <img
              src={Logo}
              alt="Lara Capital"
              onClick={() => props.closeSideNav()}
            />
          </Link>
        </div>
        <div className={styles.ProfileName}>
          <div className={styles.ProfileIcon}>
            <FaRegUserCircle />
          </div>
          <div className={styles.Name}>{localStorage.getItem("name")}</div>
          <div className={styles.UserId}>{localStorage.getItem("userId")}</div>
        </div>
        <ul className={styles.NavBar}>
          <div>
            <NavItem link="/" clicked={props.closeSideNav} exact>
              <TbReportMoney />
              Ledgers
            </NavItem>

            <NavItem link="/products" clicked={props.closeSideNav}>
              <AiTwotoneBuild />
              Products
            </NavItem>

            <NavItem link="/transactions" clicked={props.closeSideNav}>
              <AiOutlineTransaction />
              Transactions
            </NavItem>

            <NavItem link="/invoices" clicked={props.closeSideNav}>
              <FaFileInvoiceDollar />
              Invoices
            </NavItem>

            <NavItem link="/statements" clicked={props.closeSideNav}>
              <BiBookContent />
              Statements
            </NavItem>

            <NavItem link="/comments" clicked={props.closeSideNav}>
              <FaRegComments  />
              Comments
            </NavItem>

            <NavItem link="/offers" clicked={props.closeSideNav}>
              <RiCoupon3Fill />
              Offers
            </NavItem>

            <NavItem link="/my-profile" clicked={props.closeSideNav}>
              <RiUser3Line />
              My Profile
            </NavItem>

            <NavItem link="/support" clicked={props.closeSideNav}>
              <BiSupport />
              Support
            </NavItem>
          </div>
        </ul>
      </div>
      {backDrop}
    </>
  );
};

export default MainNav;
