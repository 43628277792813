import React, { useState, useEffect, useCallback, useRef } from "react";
import { useTable, usePagination } from "react-table";
import { Link } from "react-router-dom";
import axios from "../../../axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

import styles from "./Tickets.module.css";

import Button from "../../../components/Button/Button";
import Spinner from "../../../components/Spinner/Spinner";

import PrevIcon from "../../../assets/images/left-arrow-icon.svg";
import FirstIcon from "../../../assets/images/double-left-arrow-icon.svg";
import NextIcon from "../../../assets/images/right-arrow-icon.svg";
import LastIcon from "../../../assets/images/double-right-arrow-icon.svg";

const Table = ({
  columns,
  data,
  fetchData,
  pageCount: controlledPageCount,
  recordCount,
  startRecord,
  endRecord,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        sortBy: [
          {
            id: "name",
            desc: false,
          },
        ],
      },
      manualPagination: true,
      manualSortBy: true,
      autoResetPage: false,
      autoResetSortBy: false,
      pageCount: controlledPageCount,
    },
    usePagination
  );

  useEffect(() => {
    fetchData({ pageIndex, pageSize });
  }, [fetchData, pageIndex, pageSize]);

  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        {recordCount > 0 ? (
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td colSpan="10000">No records found!</td>
            </tr>
          </tbody>
        )}
      </table>
      {recordCount > 10 ? (
        <div className="TableFooter">
          <div className="TableRecordsCount"></div>
          <div className="TablePagination">
            <div className="PaginationNav">
              <Button
                type="button"
                btnType="Default"
                clicked={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                <img src={FirstIcon} alt="First Page" />
              </Button>
              <Button
                type="button"
                btnType="Default"
                clicked={() => previousPage()}
                disabled={!canPreviousPage}
              >
                <img src={PrevIcon} alt="Previous Page" />
              </Button>
              <Button
                type="button"
                btnType="Default"
                clicked={() => nextPage()}
                disabled={!canNextPage}
              >
                <img src={NextIcon} alt="Next Page" />
              </Button>
              <Button
                type="button"
                btnType="Default"
                clicked={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                <img src={LastIcon} alt="Last Page" />
              </Button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

const Index = (props) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "REF ID",
        id: "ref_id",
        accessor: (d) => (
          <Link
            to={`/support/ticket/${d.id}/view`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {d.ref_id}
          </Link>
        ),
      },
      {
        Header: "Subject",
        accessor: "subject",
      },
      {
        Header: "Raised On",
        id: "created_at",
        accessor: (d) => moment(d.created_at).format("DD MMM YYYY, hh:mm A"),
      },
      {
        Header: "Status",
        accessor: "status",
      },
    ],
    []
  );

  // We'll start our table without any data
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [recordCount, setRecordCount] = useState(0);
  const [startRecord, setStartRecord] = useState(0);
  const [endRecord, setEndRecord] = useState(0);
  const fetchIdRef = useRef(0);

  const fetchData = useCallback(({ pageSize, pageIndex }) => {
    const fetchId = ++fetchIdRef.current;

    setLoading(true);

    if (fetchId === fetchIdRef.current) {
      axios
        .get("/tickets?per-page=" + pageSize + "&page=" + (pageIndex + 1))
        .then((response) => {
          setData(response.data);
          setPageCount(response.headers["x-pagination-page-count"]);
          setRecordCount(response.headers["x-pagination-total-count"]);
          setStartRecord(response.headers["x-pagination-start-record"]);
          setEndRecord(response.headers["x-pagination-end-Record"]);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }, []);

  return (
    <React.Fragment>
      <div className={["DataTable", styles.DataTable].join(" ")}>
        <Table
          columns={columns}
          data={data}
          fetchData={fetchData}
          loading={loading}
          pageCount={pageCount}
          recordCount={recordCount}
          startRecord={startRecord}
          endRecord={endRecord}
        />
      </div>
      <Spinner isLoading={loading} />
      <ToastContainer />
    </React.Fragment>
  );
};

export default Index;
