import React, { useState } from "react";
import OtpInput from "react-otp-input";
import Countdown from "react-countdown";

import styles from "./VerifyOtpForm.module.css";

import Button from "../Button/Button";

const VerifyOtpForm = (props) => {
  const [otp, setOtp] = useState(null);
  const [resendEnabled, setResetEnabled] = useState(false);

  const onSubmit = (event) => {
    event.preventDefault();
    props.handleVerifyOtpForm(otp);
  };

  const resend = () => {
    props.handleResendOtp();
    setOtp(null);
    setResetEnabled(false);
  };

  const handleChange = (otp) => {
    setOtp(otp);
  };

  // Renderer callback with condition
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      setResetEnabled(true);
    } else {
      return (
        <span>
          Resend in {minutes}:{seconds}
        </span>
      );
    }
  };

  return (
    <div
      className={["FormContainer ModalForm", styles.FormContainer].join(" ")}
    >
      <form
        className={styles.ForgorPasswordForm}
        onSubmit={(event) => onSubmit(event)}
      >
        <div className={["FormBody", styles.FormBody].join(" ")}>
          <div className="FormGroup">
            <div className={styles.OtpInput}>
              <label className="ControlLabel">Enter OTP</label>
              <OtpInput
                value={otp}
                onChange={handleChange}
                numInputs={6}
                renderInput={(props) => <input {...props} />}
              />
            </div>
          </div>
        </div>

        <div className="FormActions">
          <Button
            type="reset"
            btnType="Secondary"
            clicked={props.handleVerifyCancel}
          >
            Cancel
          </Button>
          <Button type="submit" btnType="Primary">
            Submit
          </Button>
        </div>
        <div className={styles.Notes}>
          If you did not receive the code
          {resendEnabled ? (
            <span onClick={() => resend()} className={styles.Resend}>
              Resend
            </span>
          ) : (
            <span className={styles.ResendWait}>
              <Countdown date={Date.now() + 120000} renderer={renderer} />
            </span>
          )}
        </div>
      </form>
    </div>
  );
};

export default VerifyOtpForm;
