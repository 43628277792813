import React, { useState } from "react";

import styles from "./SearchBox.module.css";

import SearchIcon from "../../assets/images/search-icon.svg";

const SearchBox = (props) => {
  const [query, setQuery] = useState("");

  const handleSearchInput = (event) => {
    setQuery(event.target.value);
  };

  return (
    <div className={[styles.SearchBox, styles[props.theme]].join(" ")}>
      <img src={SearchIcon} alt="Search Icon" />
      <input
        className={styles.FormControl}
        type="text"
        name="SearchBox"
        value={query}
        onChange={handleSearchInput}
        autoComplete="off"
        {...props}
      />
    </div>
  );
};

export default SearchBox;
