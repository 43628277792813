import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { BiMenu } from "react-icons/bi";
import { TbLogout } from "react-icons/tb";

import { logout } from "../../store/SignIn/SignIn.Actions";

import styles from "./Header.module.css";

import Logo from "../../assets/images/logo-light.svg";

const Header = (props) => {
  const birthDay = localStorage.getItem("dateOfBirth");
  const birthDayDay = moment(birthDay).format("D");
  const birthDayMonth = moment(birthDay).format("M");

  const today = moment(new Date()).format("YYYY-MM-DD");
  const todayDay = moment(today).format("D");
  const todayMonth = moment(today).format("M");

  let showHappyBirthdayMsg = false;
  if (birthDayDay === todayDay && birthDayMonth === todayMonth) {
    showHappyBirthdayMsg = true;
  }

  return (
    <div className={styles.Header}>
      <div className={styles.ContentRight}>
        {showHappyBirthdayMsg ? (
          <div className={styles.HappyBirthdayMsg}>
            Wishing you a Very Very Happy Birthday  &#127874;  &#127882;  &#127881;
          </div>
        ) : null}
        <div
          className={styles.NavBtn}
          onClick={() => props.sideNavToggleHandler()}
        >
          <BiMenu />
        </div>
        <div className={styles.Logo}>
          <Link to="/">
            <img src={Logo} alt="Lara Capital" />
          </Link>
        </div>
      </div>
      <div className={styles.Actions}>
        <ul>
          <li onClick={() => props.onLogout()} className={styles.Logout}>
            <TbLogout />
          </li>
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.SignIn.isAuthenticated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => dispatch(logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
