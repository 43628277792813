import React, { useState, useEffect, useCallback } from "react";
import axios from "../../axios";
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import styles from "./Index.module.css";

import Button from "../../components/Button/Button";

const Index = () => {
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(null);
  const [pageSize, setPageSize] = useState(10);

  const fetchData = useCallback(() => {
    axios
      .get(`/offers?page=1&per-page=${pageSize}`)
      .then((response) => {
        setData(response.data);
        setTotalCount(response.headers["x-pagination-total-count"]);
      })
      .catch((error) => {
        if (error.response.data.code === 2001) {
          const errors = error.response.data.errors;
          toast.error(Object.values(errors[0])[0]);
        } else {
          toast.error(error.response.data.message);
        }
      });
  }, [pageSize]);

  const handleLoadMore = () => {
    setPageSize(pageSize + 10);
  };

  const handleShowInterest = (id) => {
    axios
      .post(`/offers/${id}/show-interest`)
      .then((response) => {
        toast.success(response.data.message);
      })
      .catch((error) => {
        if (error.response.data.code === 2001) {
          const errors = error.response.data.errors;
          toast.error(Object.values(errors[0])[0]);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const loadMoreBtn =
    pageSize < totalCount ? (
      <div className={styles.LoadMore}>
        <div onClick={() => handleLoadMore()}>Load more...</div>
      </div>
    ) : null;

  let offers = null;
  if (data.length) {
    offers = data.map((item) => {
      return (
        <div className={styles.Offer}>
          <div className={styles.Content}>
            <h4>{item.title}</h4>
            <p>{item.message}</p>
          </div>
          <Button
            btnType="Primary"
            type="button"
            clicked={() => handleShowInterest(item.id)}
          >
            I'm Interested
          </Button>
        </div>
      );
    });
  }

  return (
    <>
      <Helmet>
        <title>Offers | Lara Capital</title>
      </Helmet>
      <div className="PageHeader">
        <div className="ContentLeft">
          <div className="Title">
            <h1>Offers</h1>
          </div>
        </div>
      </div>
      <div className={["PageContent", styles.Offers].join(" ")}>
        {offers}
        {loadMoreBtn}
      </div>
      <ToastContainer />
    </>
  );
};

export default Index;
